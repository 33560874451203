import React from 'react'

import Layout from '../components/Layout'
import AppDetail from '../components/AppDetail'

import { colors, Typography, IconButton, Stack } from '@mui/material';
import { FaApple } from "react-icons/fa";

import { listVersions } from "../data"

export default function netrestomobile() {
    const item = listVersions.netrestomobile;

    const appleColor = colors .grey[500];
    const appStoreLink = "https://apps.apple.com/fr/app/netresto-mobile-app/id1045482962";
    
    return (
        <Layout>
            <AppDetail appData={item}>
                

                    <div style={{textAlign: "center", margin: "2em 0"}}>
                    <IconButton href={appStoreLink} >
                        <FaApple style={{verticalAlign: "middle", color: appleColor, fontSize: "2em"}} />
                    </IconButton>
                    
                    <Typography align="center" variant="string" component="div">
                        <a href={appStoreLink} target="_blank">
                            Pour obtenir la version IOS de cette application, rendez vous sur l'apple store.
                        </a>
                    </Typography>
                    </div>
                    
            </AppDetail>
        </Layout>
    )
    
}
